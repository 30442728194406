<template>
  <b-form @submit.stop.prevent="onSubmit" class="login-box" data-cy="login-form">
    <b-form-group id="group-password-old" label label-for="input-password-old" description>
      <b-form-group id="group-mobile" label label-for="input-mobile" description>
        <div style="display:flex">
          <b-form-input
            id="input-mobile"
            v-model="form.mobile"
            type="number"
            required
            placeholder="Enter Mobile"
            :state="$v.form.mobile.$dirty ? !$v.form.mobile.$error : null"
            data-cy="login-mobile"
            class="from-input input-mobile"
            :class="{ 'border-danger': errorMessages }"
            style="max-width: 299px"
          ></b-form-input>
          <b-button :disabled="!form.mobile ? true : false" class="getOtp" variant="success" @click="GetOtp"
            >Get OTP</b-button
          >
        </div>

        <b-form-invalid-feedback
          id="input-mobile-invalid"
          data-cy="login-mobile-invalid"
          class="invalid-feedback invalid-feedback-mobile"
        >
          Please enter your mobile number.
        </b-form-invalid-feedback>
      </b-form-group>
      <b-form-input
        id="input-otp"
        type="number"
        v-model="form.otp"
        required
        placeholder="Enter OTP"
        class="from-input input-otp"
      ></b-form-input>
    </b-form-group>

    <b-form-group id="group-password-new" label label-for="input-password-new" description>
      <b-form-input
        id="input-password-new"
        type="password"
        v-model="form.passwordNew"
        required
        placeholder="Enter New password"
        :state="$v.form.passwordNew.$dirty ? !$v.form.passwordNew.$error : null"
        data-cy="login-password-new"
        class="from-input input-password-new"
        :class="{ 'border-danger': errorMessages }"
      ></b-form-input>

      <b-form-invalid-feedback
        id="input-password-new-invalid"
        data-cy="login-password-new-invalid"
        class="invalid-feedback invalid-feedback-password"
      >
        Please enter your new password.
      </b-form-invalid-feedback>
    </b-form-group>

    <template v-if="successMessages || errorMessages">
      <b-row class="mb-2">
        <b-col v-if="successMessages" data-cy="login-success-message" class="text-primary message-col">{{
          successMessages
        }}</b-col>
        <b-col v-if="errorMessages" data-cy="login-error-message" class="text-danger message-col">{{
          errorMessages
        }}</b-col>
      </b-row>
    </template>
    <div class="flex-container">
      <b-form-group id="group-captcha-new" label label-for="input-captcha-new" description>
        <b-form-input
          id="input-captcha-new"
          type="text"
          v-model="enteredText"
          required
          placeholder="Enter captcha"
          :state="$v.enteredText.$dirty ? !$v.enteredText.$error : null"
          data-cy="login-captcha-new"
          class="from-input input-captcha-new box-shadow3"
          :class="{
            'border-danger': errorMessages
          }"
        ></b-form-input>

        <b-form-invalid-feedback
          id="input-captcha-new-invalid"
          data-cy="login-captcha-new-invalid"
          class="invalid-feedback invalid-feedback-captcha"
        >
          Wrong Captcha.
        </b-form-invalid-feedback>
      </b-form-group>
    </div>
    <canvas id="myCanvas" ref="myCanvas"> </canvas>
    <font-awesome-icon @click="generateCaptchaOnClick" :icon="['fas', 'retweet']" class="retweet" />
    <b-row class="mt-2">
      <b-col>
        <b-button
          data-cy="login-button"
          class="btn-login"
          type="submit"
          variant="primary"
          :disabled="$v.form.$invalid || loading"
        >
          <span class="spinner spinner-white" v-if="loading"></span>
          Save
        </b-button>
      </b-col>
      <b-col>
        <b-button @click="goToLoginScreen" class="btn-login" variant="secondary"> Back </b-button>
      </b-col>
    </b-row>
  </b-form>
</template>

<script>
import Vue from 'vue';
import { mapState, mapGetters, mapActions } from 'vuex';
import { required, minLength, numeric, maxLength } from 'vuelidate/lib/validators';
import router from '@/router';

export default {
  name: 'PasswordResetBox',
  data() {
    return {
      captchaText: '',
      enteredText: '',
      form: {
        mobile: '',
        passwordNew: '',
        otp: ''
      }
    };
  },
  validations: {
    form: {
      mobile: {
        required,
        numeric,
        minLength: minLength(10),
        maxLength: maxLength(10)
      },
      passwordNew: {
        required,
        minLength: minLength(6)
      }
    },
    enteredText: {
      required,
      minLength: minLength(6)
    }
  },
  mounted() {
    if (this.isLoggedIn) {
      // Already logged in
      this.logout({ router, silent: true });
    }
    this.captchaText = this.generateRandomText(6);
    this.generateCaptcha();
  },
  computed: {
    ...mapGetters('alert', ['errorMessages', 'successMessages']),
    ...mapState('auth', ['loading']),
    ...mapGetters('auth', ['isLoggedIn'])
  },
  methods: {
    ...mapActions('auth', ['login', 'logout']),
    ...mapActions('user', ['passwordReset', 'getOtp']),
    clearCanvas() {
      const canvas = this.$refs.myCanvas;
      const ctx = canvas.getContext('2d');
      ctx.clearRect(0, 0, canvas.width, canvas.height);
    },
    generateCaptcha() {
      this.clearCanvas();
      const canvas = this.$refs.myCanvas;
      const ctx = canvas.getContext('2d');
      ctx.font = '70px Arial';
      ctx.filter = 'blur(4px)';
      ctx.fillText(this.captchaText, 0, 80);
    },
    generateCaptchaOnClick() {
      this.captchaText = this.generateRandomText(6);
      this.generateCaptcha();
    },
    generateRandomText(length) {
      const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
      let result = '';
      for (let i = 0; i < length; i += 1) {
        result += characters.charAt(Math.floor(Math.random() * characters.length));
      }
      return result;
    },
    onSubmit() {
      if (this.enteredText === this.captchaText) {
        this.$v.form.$touch();
        if (this.$v.form.$anyError) {
          return;
        }
        const password = {
          passwordNew: this.form.passwordNew
        };
        // Form submit logic
        this.passwordReset({
          query: {
            otp: this.form.otp,
            mobile: this.form.mobile
          },
          password,
          router,
          redirectUrl: '/login'
        });
      } else {
        Vue.swal({
          title: 'Captcha Not Matched',
          icon: 'error'
        }).then(result => {
          if (result.value) {
            this.enteredText = '';
            this.captchaText = this.generateRandomText(6);
            this.generateCaptcha();
          }
        });
      }
    },
    GetOtp() {
      this.getOtp({
        mobile: this.form.mobile
      });
    },
    goToLoginScreen() {
      router.push(`/login`);
    }
  }
};
</script>
<style scoped>
.getOtp {
  margin-left: 10px;
  width: 97px;
}
#myCanvas {
  width: 330px;
  height: 40px;
}
.retweet {
  margin-bottom: 25px;
  font-size: 25px;
}
</style>
