<template>
  <b-form @submit.stop.prevent="onSubmit" class="login-box" data-cy="login-form">
    <b-form-group id="group-password-old" label label-for="input-password-old" description>
      <b-form-group id="group-mobile" label label-for="input-mobile" description>
        <b-form-input
          id="input-mobile"
          v-model="form.mobile"
          type="number"
          required
          placeholder="Enter Mobile"
          :state="$v.form.mobile.$dirty ? !$v.form.mobile.$error : null"
          data-cy="login-mobile"
          class="from-input input-mobile"
          :class="{ 'border-danger': errorMessages }"
        ></b-form-input>

        <b-form-invalid-feedback
          id="input-mobile-invalid"
          data-cy="login-mobile-invalid"
          class="invalid-feedback invalid-feedback-mobile"
        >
          Please enter your mobile number.
        </b-form-invalid-feedback>
      </b-form-group>
      <b-form-input
        id="input-password-old"
        type="password"
        v-model="form.passwordOld"
        required
        placeholder="Enter Old password"
        :state="$v.form.passwordOld.$dirty ? !$v.form.passwordOld.$error : null"
        data-cy="login-password-old"
        class="from-input input-password-old"
        :class="{ 'border-danger': errorMessages }"
      ></b-form-input>

      <b-form-invalid-feedback
        id="input-password-invalid"
        data-cy="login-password-old-invalid"
        class="invalid-feedback invalid-feedback-password"
      >
        Please enter your old password.
      </b-form-invalid-feedback>
    </b-form-group>

    <b-form-group id="group-password-new" label label-for="input-password-new" description>
      <b-form-input
        id="input-password-new"
        type="password"
        v-model="form.passwordNew"
        required
        placeholder="Enter New password"
        :state="$v.form.passwordNew.$dirty ? !$v.form.passwordNew.$error : null"
        data-cy="login-password-new"
        class="from-input input-password-new"
        :class="{ 'border-danger': errorMessages }"
      ></b-form-input>

      <b-form-invalid-feedback
        id="input-password-new-invalid"
        data-cy="login-password-new-invalid"
        class="invalid-feedback invalid-feedback-password"
      >
        Please enter your new password.
      </b-form-invalid-feedback>
    </b-form-group>

    <template v-if="successMessages || errorMessages">
      <b-row class="mb-2">
        <b-col v-if="successMessages" data-cy="login-success-message" class="text-primary message-col">{{
          successMessages
        }}</b-col>
        <b-col v-if="errorMessages" data-cy="login-error-message" class="text-danger message-col">{{
          errorMessages
        }}</b-col>
      </b-row>
    </template>

    <b-row>
      <b-col>
        <b-button
          data-cy="login-button"
          class="btn-login"
          type="submit"
          variant="primary"
          :disabled="$v.form.$invalid || loading"
        >
          <span class="spinner spinner-white" v-if="loading"></span>
          Save
        </b-button>
      </b-col>
      <b-col>
        <b-button @click="goToLoginScreen" class="btn-login" variant="secondary"> Back </b-button>
      </b-col>
    </b-row>
  </b-form>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex';
import { required, minLength, numeric, maxLength } from 'vuelidate/lib/validators';
import router from '@/router';

export default {
  name: 'PasswordChangeBox',
  data() {
    return {
      form: {
        mobile: '',
        passwordNew: '',
        passwordOld: ''
      }
    };
  },
  validations: {
    form: {
      mobile: {
        required,
        numeric,
        minLength: minLength(10),
        maxLength: maxLength(10)
      },
      passwordOld: {
        required,
        minLength: minLength(6)
      },
      passwordNew: {
        required,
        minLength: minLength(6)
      }
    }
  },
  mounted() {
    if (this.isLoggedIn) {
      // Already logged in
      this.logout({ router, silent: true });
    }
  },
  computed: {
    ...mapGetters('alert', ['errorMessages', 'successMessages']),
    ...mapState('auth', ['loading']),
    ...mapGetters('auth', ['isLoggedIn'])
  },
  methods: {
    ...mapActions('auth', ['login', 'logout']),
    ...mapActions('user', ['passwordChange']),
    onSubmit() {
      this.$v.form.$touch();
      if (this.$v.form.$anyError) {
        return;
      }
      // Form submit logic
      this.passwordChange({
        mobile: this.form.mobile,
        passwordOld: this.form.passwordOld,
        passwordNew: this.form.passwordNew,
        router,
        redirectUrl: '/login'
      });
    },
    goToLoginScreen() {
      router.push(`/login`);
    }
  }
};
</script>
