<template>
  <b-form @submit.stop.prevent="onSubmit" class="login-box" data-cy="login-form">
    <b-form-group id="group-mobile" label label-for="input-mobile" description>
      <b-form-input
        id="input-mobile"
        v-model="form.mobile"
        type="number"
        required
        placeholder="Enter Mobile"
        :state="$v.form.mobile.$dirty ? !$v.form.mobile.$error : null"
        data-cy="login-mobile"
        :formatter="limit"
        class="from-input input-mobile"
        :class="{ 'border-danger': errorMessages }"
      ></b-form-input>

      <b-form-invalid-feedback
        id="input-mobile-invalid"
        data-cy="login-mobile-invalid"
        class="invalid-feedback invalid-feedback-mobile"
      >
        Please enter your mobile number.
      </b-form-invalid-feedback>
    </b-form-group>

    <b-form-group id="group-password" label label-for="input-password" description>
      <b-form-input
        id="input-password"
        type="password"
        v-model="form.password"
        required
        placeholder="Enter password"
        :state="$v.form.password.$dirty ? !$v.form.password.$error : null"
        data-cy="login-password"
        class="from-input input-password"
        :class="{ 'border-danger': errorMessages }"
      ></b-form-input>

      <b-form-invalid-feedback
        id="input-password-invalid"
        data-cy="login-password-invalid"
        class="invalid-feedback invalid-feedback-password"
      >
        Please enter your password.
      </b-form-invalid-feedback>
    </b-form-group>

    <template v-if="successMessages || errorMessages">
      <b-row class="mb-2">
        <b-col v-if="successMessages" data-cy="login-success-message" class="text-primary message-col">{{
          successMessages
        }}</b-col>
        <b-col v-if="errorMessages" data-cy="login-error-message" class="text-danger message-col">{{
          errorMessages
        }}</b-col>
      </b-row>
    </template>

    <b-row>
      <b-col>
        <b-button
          data-cy="login-button"
          class="btn-login"
          type="submit"
          variant="primary"
          :disabled="$v.form.$invalid || loading"
        >
          <span class="spinner spinner-white" v-if="loading"></span>
          Login
        </b-button>
      </b-col>
      <b-col>
        <b-button @click="goToPasswordChangeScreen" class="btn-login" variant="light"> Change Password </b-button>
      </b-col>
      <b-col>
        <b-button @click="goToPasswordResetScreen" class="btn-login" variant="light"> Reset Password </b-button>
      </b-col>
    </b-row>
  </b-form>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex';
import { required, minLength, numeric, maxLength } from 'vuelidate/lib/validators';
import router from '@/router';

export default {
  name: 'LoginBox',
  data() {
    return {
      form: {
        mobile: '',
        password: ''
      }
    };
  },
  validations: {
    form: {
      mobile: {
        required,
        numeric,
        minLength: minLength(10),
        maxLength: maxLength(10)
      },
      password: {
        required,
        minLength: minLength(6)
      }
    }
  },
  mounted() {
    if (this.isLoggedIn) {
      // Already logged in
      this.logout({ router, silent: true });
    }
  },
  computed: {
    ...mapGetters('alert', ['errorMessages', 'successMessages']),
    ...mapState('auth', ['loading']),
    ...mapGetters('auth', ['isLoggedIn'])
  },
  methods: {
    ...mapActions('auth', ['login', 'logout']),
    limit(e) {
      return e.slice(0, 10);
    },
    onSubmit() {
      this.$v.form.$touch();
      if (this.$v.form.$anyError) {
        return;
      }

      // Form submit logic
      this.login({ mobile: this.form.mobile, password: this.form.password, router });
    },
    goToPasswordChangeScreen() {
      router.push(`/change-password`);
    },
    goToPasswordResetScreen() {
      router.push(`/reset-password`);
    }
  }
};
</script>
